<template>
  <div>
    <!-- list filters -->
    <v-card>
      <v-card-title>
        Seguimiento a tareas
      </v-card-title>

      <v-divider class="mt-2"></v-divider>
      <v-card-text class="d-flex align-center justify-space-between mb-4">
        <v-col
          cols="8"
        >
          <v-autocomplete
            v-model="project"
            :items="selectProjectsList"
            label="Lista de proyectos"
            hide-details
            outlined
            :loading="loadingP"
          ></v-autocomplete>
        </v-col>

        <v-col
          cols="3"
        >
          <v-btn
            color="primary"
            class="mb-1 me-3"
            small
            @click="getTasks()"
            :disabled="!project"
            :loading="loadingT"
          >
            <v-icon>{{ icons.mdiFileFindOutline }}</v-icon>
            <span>Consultar tareas</span>
          </v-btn>
        </v-col>
      </v-card-text>
      <v-divider></v-divider>
      <div v-if="loaded">
        <v-col cols="12">
          <filter-by v-model="newItems" :filterFields="filterFields" :filterLists="filterLists"></filter-by>
        </v-col>
        <ve-table
          :columns="columns"
          :table-data="newItems"
          style="width:100%"
          :scroll-width="scroll"
          :cell-style-option="cellStyleOption"
          :editOption="editOption"
          rowKeyFieldName="rowKey"
          border-y
        />
        <div v-show="showEmpty" class="empty-data">No hay datos disponibles.</div>
      </div>
    </v-card>
  </div>
</template>

<script>
import {
  mdiAccount,
  mdiExportVariant,
  mdiFileFindOutline,
  mdiDotsVertical,
  mdiPencilOutline,
  mdiFileChartOutline,
  mdiTextBoxCheckOutline,
  mdiAccountGroupOutline,
  mdiSendCircleOutline,
} from '@mdi/js'
import apiAxios from '@/plugins/axios'
import FilterBy from '@/components/widgets/filter/FilterBy.vue'
import moment from 'moment'

export default {
  components: { FilterBy },
  data() {
    return {
      scroll: 1500,
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          return 'table-header-cell-class'
        },
        bodyCellClass: ({ row, column, rowIndex }) => {
          if (column.field === 'advance') {
            return 'table-body-cell-class1'
          }
          if (column.field !== 'advance' && column.field !== 'priority' && column.field !== 'stated_at') {
            return 'table-body-cell-class'
          }
        },
      },
      editOption: {
        // cell value change
        cellValueChange: ({ row, column }) => {
          row['advance'] = Number(row.advance)
          row['stated_at'] = this.statusVariantText(Number(row.advance))
          this.updateTask(row).then(() => {
            this.getTasks()
          })
        },
      },
      icons: {
        mdiAccount,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileFindOutline,
        mdiSendCircleOutline,
      },
      columns: [
        { field: 'task_manager', key: 'task_manager', title: 'RESPONSABLE', fixed: 'left' },
        { field: 'categories', key: 'categories', title: 'CATEGORÍA', fixed: 'left', width: 200 },
        {
          field: 'priority',
          key: 'priority',
          title: 'PRIORIDAD',
          fixed: 'left',
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const cellData = row[column.field]
            return (
              <span
                class='skill-span'
                style={
                  'background-color:' +
                  this.resolvePriorityChip(cellData) +
                  ';' +
                  'color:' +
                  this.resolvePriorityVariant(cellData)
                }
              >
                {this.resolvePriorityText(cellData)}
              </span>
            )
          },
        },
        {
          field: 'type_project_status',
          key: 'type_project_status',
          title: 'ETAPA',
          fixed: 'left',
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const cellData = row[column.field]
            return <span>{this.getStatus(cellData)}</span>
          },
        },
        { field: 'code', key: 'code', title: 'CÓDIGO' },
        { field: 'description', key: 'description', title: 'DESCRIPCIÓN' },
        {
          field: 'advance',
          key: 'advance',
          title: 'AVANCE',
          edit: true,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const cellData = row[column.field]
            const colorClass = cellData > 60 ? 'demo-blue' : cellData > 30 ? 'demo-orange' : 'demo-red'
            return (
              <div class='proficiency-span-container'>
                <span class={'proficiency-span ' + colorClass} style={'width:' + cellData + '%;'}>
                  {cellData}%
                </span>
              </div>
            )
          },
        },
        { field: 'observations', key: 'observations', title: 'NOVEDADES', edit: true },
        {
          field: 'start_date',
          key: 'start_date',
          title: 'INICIO',
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const cellData = row[column.field]
            return <span>{moment(cellData).format('DD-MMM-YYYY')}</span>
          },
        },
        { field: 'assigned', key: 'assigned', title: 'PROYECTADO' },
        { field: 'remaining', key: 'remaining', title: 'FALTANTE' },
        {
          field: 'stated_at',
          key: 'stated_at',
          title: 'ESTADO',
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const cellData = row[column.field]
            return (
              <span class='status-span' style={'color:' + this.statusVariant(cellData)}>
                {cellData}
              </span>
            )
          },
        },
      ],
      filterFields: [
        { text: 'RESPONSABLE', value: 'task_manager', type: 'text' },
        { text: 'CATEGORÍA', value: 'categories', type: 'text' },
        { text: 'PRIORIDAD', value: 'priority', type: 'lookup' },
        { text: 'ETAPA', value: 'type_project_status', type: 'text' },
        { text: 'CÓDIGO', value: 'code', type: 'text' },
        { text: 'DESCRIPCIÓN', value: 'description', type: 'text' },
        { text: 'AVANCE', value: 'advance', type: 'number' },
        { text: 'NOVEDADES', value: 'observations', type: 'text' },
        { text: 'INICIO', value: 'start_date', type: 'date' },
        { text: 'PROYECTADO', value: 'assigned', type: 'number' },
        { text: 'FALTANTE', value: 'remaining', type: 'number' },
        { text: 'ESTADO', value: 'stated_at', type: 'lookup' },
      ],
      filterLists: [
        {
          value: 'priority',
          list: [
            { text: 'Muy alta', value: 'highest' },
            { text: 'Alta', value: 'high' },
            { text: 'Normal', value: 'normal' },
            { text: 'Baja', value: 'low' },
            { text: 'Muy baja', value: 'lowest' },
          ],
        },
        {
          value: 'stated_at',
          list: [
            { text: 'INICIO', value: 'INICIO' },
            { text: 'EJECUCIÓN', value: 'EJECUCIÓN' },
            { text: 'FINALIZADO', value: 'FINALIZADO' },
          ],
        },
      ],
      newItems: [],
      loaded: false,
      loadingP: true,
      loadingT: false,
      project: '',
      showEmpty: false,
      select: [],
      tableData: [],
      tasks: [],
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  mounted() {
    this.getProjects()
      .then(res => {
        this.select = res
        this.loadingP = false
      })
      .catch(() => (this.loadingP = false))
  },
  watch: {
    tasks(val) {
      this.tableData = val.map((task, i) => {
        let ele = {
          task_id: task.task_id,
          task_resource_id: task.task_resource_id,
          task_manager: task.task_manager,
          priority: task.priority,
          categories: task.categories,
          type_project_status: task.type_project_status,
          code: task.code,
          description: task.description,
          advance: task.advance,
          observations: task.observations,
          assigned: task.assigned,
          remaining: task.remaining,
          stated_at: this.statusVariantText(Number(task.advance)),
          start_date: task.start_date,
          rowKey: i,
          task_tracking_id: task.task_tracking_id,
        }
        return ele
      })
    },
    tableData: {
      handler(val) {
        this.newItems = val
      },
      deep: true,
    },
    newItems: {
      handler(val) {
        if (val.length === 0) {
          this.showEmpty = true
          this.scroll = null
        } else {
          this.showEmpty = false
          this.scroll = 1500
        }
      },
      deep: true,
    },
  },
  computed: {
    selectProjectsList() {
      let list = []
      if (this.select) {
        list = this.select.map(type => {
          const ele = {
            value: type.project_id,
            text: type.code + ' - ' + type.description,
          }
          return ele
        })
      }
      return list
    },
  },
  methods: {
    async getProjects() {
      try {
        const res = await apiAxios.get('task_projects/?user_id=' + this.userData.id)
        return JSON.parse(res.data.object[0]['taskproject'])['task_projects']
      } catch (error) {
        console.log(error)
      }
    },
    async updateTask(item) {
      try {
        let url = 'tasks/' + item.task_id
        const payload = {
          id: item.task_id,
          task_resources_attributes: [
            {
              id: item.task_resource_id,
              task_trackings_attributes: [
                {
                  user_id: this.userData.id,
                  start_date: item.start_date,
                  percentage: item.advance,
                  observations: item.observations,
                },
              ],
            },
          ],
        }
        if (item.task_tracking_id) {
          payload['task_resources_attributes'][0]['task_trackings_attributes'][0].id = item.task_tracking_id
        }

        const res = await apiAxios.put(url, { task: payload })
        return res
      } catch (error) {
        console.log(error)
      }
    },
    getStatus(status) {
      if (status === 'ideation') return 'Ideación'
      if (status === 'formulation') return 'Formulación'
      if (status === 'committe') return 'Comité'
      return 'Revisar api'
    },
    async getTasks() {
      this.loadingT = true
      try {
        const res = await apiAxios.get(`task_trackings/?user_id=${this.userData.id}&project_id=${this.project}`)
        this.loadingT = false
        this.tasks = JSON.parse(res.data.object[0]['tasktracking'])['task_trackings']
        this.loaded = true
      } catch (error) {
        console.log(error)
        this.loadingT = false
      }
    },
    statusVariant(status) {
      if (status === 'INICIO') return '#FF4C51'
      if (status === 'EJECUCIÓN') return '#FFB400'
      else return '#56CA00'
    },
    statusVariantText(status) {
      if (status === 0) return 'INICIO'
      if (status >= 0 && status <= 90) return 'EJECUCIÓN'
      if (status > 90) return 'FINALIZADO'
    },
    resolvePriorityVariant(status) {
      if (status === 'normal') return '#F57F17'
      if (status === 'low') return '#1B5E20'
      if (status === 'lowest') return '#0D47A1'
      if (status === 'high') return '#E65100'
      if (status === 'highest') return '#B71C1C'
    },
    resolvePriorityChip(status) {
      if (status === 'normal') return '#FFF9C4'
      if (status === 'low') return '#C8E6C9'
      if (status === 'lowest') return '#BBDEFB'
      if (status === 'high') return '#FFE0B2'
      if (status === 'highest') return '#FFCDD2'
    },
    resolvePriorityText(status) {
      if (status === 'normal') return 'Normal'
      if (status === 'low') return 'Baja'
      if (status === 'lowest') return 'Muy baja'
      if (status === 'high') return 'Alta'
      if (status === 'highest') return 'Muy alta'
    },
  },
}
</script>

<style >
.empty-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  color: rgba(94, 86, 105, 0.68) !important;
  font-size: 14px;
  border: 1px solid #eee;
  border-top: 0;
}
.skill-span {
  padding: 3px 8px;
  border-radius: 5px;
}
.table-header-cell-class {
  font-size: 12px !important;
}
.table-body-cell-class1 {
  padding: 0 !important;
}
.table-body-cell-class {
  color: rgba(94, 86, 105, 0.68) !important;
}
.proficiency-span-container {
  height: 100%;
  text-align: left;
}
.proficiency-span {
  height: 100%;
  display: inline-flex;
  align-items: center;
  padding-left: 10px;
  font-weight: bold;
  color: #555;
}
.demo-blue {
  background-color: RGBA(24, 144, 255, 0.7);
}
.demo-orange {
  background-color: RGBA(255, 179, 0, 0.7);
}
.demo-red {
  background-color: RGBA(244, 93, 81, 0.7);
}
</style>
